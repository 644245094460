
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React from "react"
import Layout from "../src/components/Layout"
import SEO from "../src/components/SEO"
// Sections
import PostHeader from '../src/layouts/PostHeader';
import PostContentWithTime from '../src/layouts/PostContentWithTime';
import PostBibliography from '../src/layouts/PostBibliography';
import RelatedPost from '../src/layouts/RelatedPost';
const Post = ({ pageContext }) => {
  const {
    post: { title, pageBuilder, featuredImage },
  } = pageContext
  const layouts = pageBuilder.layouts || []
  const hiddenHeader = pageBuilder.hiddenHeader
  return (
    <Layout hiddenHeader={hiddenHeader}>
      <SEO title={title} image={featuredImage.sourceUrl}/>
      {
        layouts.map((layout, index) => {
          
              if (layout.fieldGroupName === 'post_Pagebuilder_Layouts_PostHeader') {
                  return <PostHeader {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'post_Pagebuilder_Layouts_PostContentWithTime') {
                  return <PostContentWithTime {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'post_Pagebuilder_Layouts_PostBibliography') {
                  return <PostBibliography {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'post_Pagebuilder_Layouts_RelatedPost') {
                  return <RelatedPost {...layout} key={index} />;
              }
            
        })
      }
    </Layout>
  )
}
export default Post
  