import React from "react"
import styled from 'styled-components'
import { blogURI } from "../../../globals"
import { Link } from 'gatsby'
import FluidImage from "../../components/FluidImage"
import LinkTitle  from "../../components/LinkTitle/LinkTitle"
import CategoryLink  from "../../components/CategoryLink/CategoryLink"
const PostItem = styled.div`
   color:  ${({theme}) => theme.colors.dark};
  .gatsby-image-wrapper > div {
     padding-bottom: 62% !important /* your aspect ratio */
  }
`;


const Post = ({ post, index, margin, col, i }) => {

  const { uri, title, featuredImage, categories } = post;

  return (
    <PostItem
      data-sal="fade"
      data-sal-delay={index}
      data-sal-duration="1200"
      data-sal-easing="ease"
      className={`${col} text-center text-md-left ${margin} ${(i && i>1) ? 'd-none d-md-block displayClick' : ''} `}>
      {featuredImage && <Link to={`${blogURI}${uri}`}><FluidImage image={featuredImage} className="mb-3" /></Link>}
      <div className={`d-flex pb-2 justify-content-center justify-content-md-start text-center text-md-left`}>
        {categories && categories.nodes.map(category => <CategoryLink as={Link} key={category.id} to={`${blogURI}${category.uri}`}>{category.name}</CategoryLink>)}
      </div>
      <LinkTitle as={Link} to={`${blogURI}${uri}`} >{title}</LinkTitle>
    </PostItem>
  )
}

export default Post
