import styled from 'styled-components'

const H1 = styled.h1`
  font-size:  ${({isBig}) => isBig ? '52px' : '48px'};
  letter-spacing: ${({isBig}) => isBig ? '1.24px' : '1px'};
  line-height: ${({isBig}) => isBig ? '66px' : '60px'};
  max-width: ${({MaxWidth}) => MaxWidth ? '990px' : 'none'};
  color:  ${({textColor}) => textColor};
  font-weight: normal;
  ${({theme}) => theme.media.mobile} {
     font-size:  ${({isBig}) => isBig ? '32px' : '32px'};
     letter-spacing: 0.89px;
     line-height: 42px;
  }
`;

export default H1;