import React, {Component} from "react";
import TextContent from '../../components/Text/TextContent'
import styled from 'styled-components';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Read = styled.div`
  left: 80px;
  top: 16px;
  text-align: center;
  
  ${({theme}) => theme.media.mobile} {
    position: relative !important;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const ArrowUp = styled.div`
  position: fixed;
  bottom: 30px;
  right: 15px;
  opacity: 0;
  transition: all 0.3s;
  &.active {
    opacity: 1;
  }
  
`;


export default class PostContentWithTime extends Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    let currentScrollPos = window.pageYOffset;
    let footerOffset = document.getElementById("footer").offsetTop;
    let windowHeight = window.innerHeight;
    if ( (window.scrollY > windowHeight + 200) && (footerOffset > currentScrollPos + windowHeight + 600) ) {
      document.getElementById("arrowId").classList.add("active");
    } else {
      document.getElementById("arrowId").classList.remove("active");
    }
  };

  render() {

    let text = this.props.text;

    /* let textTag = text.replace(/<p>/g, '<p data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
    textTag = textTag.replace(/<h1>/g, '<h1 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
    textTag = textTag.replace(/<h2>/g, '<h2 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
    textTag = textTag.replace(/<h3>/g, '<h3 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
    textTag = textTag.replace(/<h4>/g, '<h4 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">');
    textTag = textTag.replace(/<h6>/g, '<h6 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease">'); */

    const wordsPerMinute = 200; // Average case.
    let result = "";

    let textLength = text.split(" ").length; // Split by words
    if (textLength > 0) {
      result = Math.ceil(textLength / wordsPerMinute);
    }

    function handleClick() {
      window.scroll({
        top: 0,
        behavior: "smooth"
      })
    }

    return (
      <div className="container text-left">
        <div className="row">
          <div className="col-12 px-4 px-md-0">
            <Read data-sal="slide-up" data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease"
                  className="position-absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="38px" height="86px" viewBox="0 0 38 86" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.800000012">
                  <g transform="translate(-168.000000, -941.000000)">
                    <g transform="translate(169.000000, 941.000000)">
                      <path d="M0,25.6134194 L18,0 L36,25.6134194 L18,53.1 L0,25.6134194 Z" id="Combined-Shape"
                            stroke="#191328" strokeWidth="0.72"/>
                      <text fontFamily="PublicSans-Light, Public Sans" fontSize="7.92" fontWeight="300" fill="#191328">
                        <tspan x="7.99" y="30.32">{result} min</tspan>
                      </text>
                      <text fontFamily="PublicSans-Light, Public Sans" fontSize="7.92" fontWeight="300" fill="#191328">
                        <tspan x="9.75726" y="70.64">czas</tspan>
                        <tspan x="2.59956" y="80.64">czytania</tspan>
                      </text>
                      <line x1="1.08" y1="85.32" x2="36.36" y2="85.32" id="Line-2" stroke="#191328" strokeWidth="0.72"
                            strokeLinecap="square"/>
                    </g>
                  </g>
                </g>
              </svg>
            </Read>
            <TextContent className="textContent" dangerouslySetInnerHTML={{__html: text}}/>
          </div>
        </div>
        <ArrowUp id="arrowId" className="d-block d-md-none" onClick={handleClick}>
          <svg width="72px" height="71px" viewBox="0 0 72 71" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
              <g transform="translate(-278.000000, -1872.000000)">
                <g transform="translate(278.000000, 1872.000000)">
                  <ellipse id="Oval-Copy" fill="#FFFFFF" opacity="0.9" cx="36" cy="35.5" rx="36" ry="35.5"></ellipse>
                  <path
                    d="M35.5,18.9801356 L35.9357878,19.7548694 L40.4357878,27.7548694 L40.6809184,28.1906571 L39.8093429,28.6809184 L39.5642122,28.2451306 L36,21.908 L36,52 L35,52 L35,21.908 L31.4357878,28.2451306 L31.1906571,28.6809184 L30.3190816,28.1906571 L30.5642122,27.7548694 L35.0642122,19.7548694 L35.5,18.9801356 Z"
                    fill="#8FB0DE" fill-rule="nonzero" opacity="0.800000012"></path>
                </g>
              </g>
            </g>
          </svg>
        </ArrowUp>
      </div>
    )
  }
}

