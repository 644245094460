import React from "react"
import styled from 'styled-components';
import IconImg from "../../images/icon.svg"
const StyledWrapper = styled.div`
  position: relative;
  max-width: 710px;
`;


const CertText = styled.span`
  color: ${({theme}) => theme.colors.dark};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.8px;
  line-height: 30px;
  margin-bottom: 10px;
   ${({theme}) => theme.media.mobile} {
      word-break: break-all;
   }
`;

const PostBibliography = ({title, list}) => {




  return (
      <StyledWrapper data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease" className="container px-3 pb-0 pb-md-3 pt-0 pt-md-4">
        <div className="row align-items-center justify-content-center pt-3 pb-4 pt-md-5 pb-md-5">
          <img src={IconImg} alt="icon decorator" />
        </div>
        <div class="row mb-2 justify-content-center justify-content-md-start text-center text-md-left px-4 px-md-0">
          <CertText >{title}</CertText>
        </div>
        <div className="row d-block d-md-flex flex-column mt-3 px-4 px-md-0 text-center text-md-left">
          {list && list.map((item) => <CertText className="d-block mb-2"><i>{item.title}</i> {item.author}</CertText>)}
        </div>
      </StyledWrapper>
  )
}

export default PostBibliography
