import styled from 'styled-components'

const TextContent = styled.div`
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 32px;
  max-width: 710px;
  margin-left: auto;
  margin-right: auto;
  font-variation-settings: 'wdth' 600, 'wght' 300;
`;

export default TextContent;

