import styled from 'styled-components'

const LinkTitle = styled.span`
  font-size: 21px;
  font-weight: 300;
  font-variation-settings: 'wdth' 700, 'wght' 300;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: ${({textCenter}) => textCenter ? 'center' : 'left'};
  color: ${({theme}) => theme.colors.dark};
  transition: all 0.3s;
   text-decoration: none !important;
  :hover {
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
  }
  
  ${({theme}) => theme.media.mobile} {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.86px;
    line-height: 28px;
    text-align: center;
  }
  
`;

export default LinkTitle;