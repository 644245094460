import React, {Component} from "react";
import styled from 'styled-components';
import Post from '../../components/Post/Post'
import Slider from "react-slick";

const StyledWrapper = styled.div`
  position: relative;
`;

export default class RelatedPost extends Component {


  render() {

    const posts = this.props.posts;

    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
            dots: false
          }
        }
      ]
    };
    return (
      <StyledWrapper className="container-fluid px-0 px-md-5 pt-5 pb-0 pb-md-4">
        <div className=" text-center pt-4"
             data-sal="slide-up"
             data-sal-delay="300"
             data-sal-duration="1000"
             data-sal-easing="ease">
          <Slider ref={c => (this.slider = c)} {...settings}>
            {posts && posts.map((post, index) => <Post key={post.id} post={post} index={(index % 2 + 1) * 300}
                                                       margin="pb-0 pb-md-4" col="mx-3 sal-animate"/>)}
          </Slider>
        </div>
      </StyledWrapper>
    )
  }
}