import React from "react"
import {Link} from "gatsby"
import { blogURI } from "../../../globals"
import styled from 'styled-components';
import FluidImage from '../../components/FluidImage';
import H1 from "../../components/H1/H1";
import CategoryLink from "../../components/CategoryLink/CategoryLink";
const StyledWrapper = styled.div`
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  ${({theme}) => theme.media.mobile} {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  
`;

const PostHeader = ({text, image, category}) => {

  return (
      <StyledWrapper className="container-fluid px-0 px-md-5 pb-0 pb-md-3">
        <div className="row px-0 align-items-center">
          <div data-sal="slide-up"
               data-sal-delay="100"
               data-sal-duration="1000"
               data-sal-easing="ease"
               className="col-12 col-md-6 pr-0 pr-md-5 order-2 order-md-1 px-5 px-md-0 mt-2 mt-md-0">
            <FluidImage image={image}/>
          </div>
          <div className="col-12 col-md-6 pl-0 pl-md-5 text-center pr-0 pr-md-5 order-1 order-md-2 px-5 px-md-0">
            <div data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease" class="d-flex justify-content-center mb-4 mb-md-5">
              {category && category.map((cat) => <CategoryLink as={Link} to={`${blogURI}${cat.uri}`} key={cat.id} marginLeft>{cat.name}</CategoryLink>)}
            </div>
            <H1 data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-duration="1000"
                  data-sal-easing="ease">{text}</H1>
          </div>
        </div>
      </StyledWrapper>
  )
}

export default PostHeader
