import styled from 'styled-components'

const CategoryLink = styled.span`
  position: relative;
  color: ${({theme}) => theme.colors.primary};
  font-family: "Public Sans";
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  margin-left:  ${({marginLeft}) => marginLeft ? '10px' : '0px'};
  margin-right: ${({marginLeft}) => marginLeft ? '10px' : '20px'};
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  transition: border-color .55s ease-out;
  padding-bottom: 1px;
  :hover {
    color: ${({theme}) => theme.colors.primary};
    border-bottom: 1px solid ${({theme}) => theme.colors.primary};
    transition: border-color .35s ease-in;
    text-decoration: none;
  }
  
   ${({theme}) => theme.media.mobile} {
        font-size: 12px;
        letter-spacing: 0.86px;
        line-height: 14px;
        margin-left:  ${({marginLeft}) => marginLeft ? '5px' : '5px'};
        margin-right: ${({marginLeft}) => marginLeft ? '5px' : '5px'};
   }
  
`;

export default CategoryLink;